import { Component, OnInit } from '@angular/core';
import { User, Role} from '../../model/user';
import { AuthService } from '../../pages/auth/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  
  currentUser: User =  {};
  roles : Array<string> = [];
  isLoggedIn = false;

  constructor(private authService: AuthService, private router: Router) { }

  ngOnInit(): void {

    this.isLoggedIn = this.authService.isLoggedIn();
    if(this.isLoggedIn){
      this.currentUser = this.authService.getUser();
      if(this.currentUser && this.currentUser.roles){
        this.currentUser.roles?.forEach((role :Role) => {
          this.roles.push(role.name); 
        });
      }
    }
  }

  logout(): void {

    this.authService.clean();
    this.isLoggedIn = this.authService.isLoggedIn();
    this.router.navigate(['auth/login']);
    
  }
}