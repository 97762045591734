<footer class="nga-footer">
  <div class="nga-hint">
    <div class="container">
      <div class="row p-4"></div>
    </div>
  </div>
  <div class="container py-5 text-center text-lg-start">
    <div class="row">
      <div class="col-lg-2 col-mb-6 col-xs-12">
        <h2 class="h5">DEVOPS</h2>
        <hr class="text-white mb-4 mt-0 d-inline-block" style="width: 120px" />
        <p>
          @for (tag of tags; track tag) {
          <a [routerLink]="['/tags', tag]">{{ tag }}</a
          >, }
        </p>
      </div>
      <div class="col-lg-2 col-mb-6 col-xs-12">
        <h2 class="h5">FORMATION OPS</h2>
        <hr class="text-white mt-0 d-inline-block" style="width: 70px" />
        <ul class="list-unstyled">
          <li class="mb-2"><a href="/tags/OPS">Formations OPS</a></li>
          <li class="mb-2"><a href="/tags/Ansible">Ansible</a></li>
          <li class="mb-2"><a href="/tags/Terraform">Terraform</a></li>
          <li class="mb-2">
            <a href="/tags/CICD">GITAB CI/CD</a>
          </li>
        </ul>
      </div>
      <div class="col-lg-2 col-mb-6 col-xs-12">
        <h2 class="h5">FORMATION DEV</h2>
        <hr class="text-white mt-0 d-inline-block" style="width: 70px" />
        <ul class="list-unstyled">
          <li class="mb-2"><a [routerLink]="['/tags', 'JAVA']">JAVA</a></li>
          <li class="mb-2"><a [routerLink]="['/tags', 'Kotlin']">Kotlin</a></li>
          <li class="mb-2">
            <a [routerLink]="['/tags', 'Angular']">Angular</a>
          </li>
          <li class="mb-2">
            <a [routerLink]="['/tags', 'ReactJS']">ReactJS</a>
          </li>
        </ul>
      </div>
      <div class="col-lg-2 col-mb-6 col-xs-12">
        <h2 class="h5">DEVOPS-WEB</h2>
        <hr class="text-white mt-0 d-inline-block" style="width: 70px" />
        <ul class="list-unstyled">
          <li class="mb-2">
            <a [routerLink]="['/pages', 'TEAMDEVOPS']">Team Formateurs</a>
          </li>
          <!-- <li class="mb-2"><a href="/blog">Blog</a></li> -->
          <li class="mb-2"><a [routerLink]="['/pages', 'FAQ']">FAQ</a></li>
          <li class="mb-2"><a href="/contact">Contact</a></li>
        </ul>
      </div>
      <div class="col-lg-2 col-mb-6 col-xs-12">
        <h2 class="h5">Conditions</h2>
        <hr class="text-white mt-0 d-inline-block" style="width: 70px" />
        <ul class="list-unstyled">
          <li class="mb-2">
            <a [routerLink]="['/pages', 'CGU']">C.G.U</a>
          </li>
          <li class="mb-2">
            <a [routerLink]="['/pages', 'CGV']">C.G.V</a>
          </li>
          <li class="mb-2">
            <a [routerLink]="['/pages', 'LEGAL']">Mentions légales</a>
          </li>
          <li class="mb-2">
            <a
              href="https://creativecommons.org/licenses/by-nc-nd/4.0/"
              aria-label="CC BY-NC-ND 4.0 Licence"
              target="_blank"
              rel="noopener noreferrer nofollow license"
              >Licence CC BY-NC-ND 4.0</a
            >
          </li>
        </ul>
      </div>
    </div>
    <div class="row">
      <a
        type="button"
        class="nga-btn-social nga-btn-linkedin"
        href="https://www.linkedin.com/in/devops-web-fr/"
        aria-label="Linkedin devops-web"
      >
        <i class="fab fa-linkedin-in"></i>
      </a>
      <a
        type="button"
        class="nga-btn-social nga-btn-twitter"
        href="https://twitter.com/devops-web-fr"
        aria-label="Twitter devops-web"
      >
        <i class="fab fa-twitter"></i>
      </a>
      <a
        type="button"
        class="nga-btn-social nga-btn-github"
        href="https://github.com/devops-web-fr"
        aria-label="Github devops-web"
      >
        <i class="fab fa-github"></i
      ></a>
      <a
        type="button"
        class="nga-btn-social nga-btn-gitlab"
        href="https://gitlab.com/devops-web-fr"
        aria-label="Gitlab devops-web"
      >
        <i class="fab fa-gitlab"></i
      ></a>

      <a
        type="button"
        class="nga-btn-social nga-btn-facebook"
        href="https://facebook.com/devops-web-fr"
        aria-label="Gitlab devops-web"
      >
        <i class="fa-brands fa-facebook-f"></i>
        ></a
      >
    </div>
  </div>
  <div class="py-3 text-center nga-hint">
    <div class="container">
      |<a href="https://www.devops-web.fr/"> copyrights www.devops-web.fr</a> |
    </div>
  </div>
  <!-- Google tag (gtag.js) -->
  <script
    async
    src="https://www.googletagmanager.com/gtag/js?id=G-B4CXZE257P"
  ></script>
  <script>
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      dataLayer.push(arguments);
    }
    gtag("js", new Date());

    gtag("config", "G-B4CXZE257P");
  </script>

  <!-- Google captcha -->
  <script src="https://www.google.com/recaptcha/api.js"></script>
  <!-- Your code -->
</footer>
