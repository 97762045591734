import { Response } from 'express';
import { CommonModule, isPlatformServer } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, Optional, PLATFORM_ID, inject } from '@angular/core';


import { EditorComponent } from '../editor/editor.component';
import { RESPONSE } from '../../server.token';

@Component({
  selector: 'app-page-404',
  standalone: true,
  imports: [EditorComponent],
  templateUrl: './page-404.component.html',
  styleUrls: ['./page-404.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})


export class Page404Component  {

  platformId = inject(PLATFORM_ID);
  constructor(
      @Optional() @Inject(RESPONSE) private response: Response
  ) {
      // Only executes server-side
      if (isPlatformServer(this.platformId)) {
          this.response?.status(404);
      }
  }
}

