import { Routes } from '@angular/router';
import { Page404Component } from './components/page-404/page-404.component';

export const routes: Routes = [
    //{ path: '',title:"Accueil", component: HomeComponent },
      {
        path: '',  
        loadChildren: () => import('./pages/home/home.routes').then(routes => routes.routes),
      },
      {
        path: 'admin',  
        loadChildren: () => import('./pages/admin/admin.routes').then(routes => routes.routes),
      },
      {
        path: 'auth',
        loadChildren: () => import('./pages/auth/auth.routes').then(routes => routes.routes)
      },
      {
        path: 'account',
        loadChildren: () => import('./pages/account/account.routes').then(routes => routes.routes)
      },
      {
        path: 'formations',
        loadChildren: () => import('./pages/formations/formations.routes').then(routes => routes.routes)
      },
      {
        path: 'formations/:formationId',
        loadComponent: () => import(`./pages/formations/details/formation-details.component`)
          .then(mod => mod.FormationDetailsComponent)
      },
      {
        path: 'articles/:articleId',
        loadComponent: () => import(`./pages/articles/articles.component`)
          .then(mod => mod.ArticlesPageComponent)
      },
      {
        path: 'prestations',
        loadChildren: () => import('./pages/prestations/prestations.routes').then(routes => routes.routes)
      },
      {
        path: 'prestations/:prestationId',
        loadComponent: () => import('./pages/prestations/details/prestation-details.component').then(mod => mod.PrestationDetailsComponent)
      },
      {
        path: 'pages/:pageId',
        loadChildren: () => import('./pages/page/page.routes').then(routes => routes.routes)
      },
      {
        path: 'tags/:tag',
        loadChildren: () => import('./pages/tags/tags.routes').then(routes => routes.routes)
      },
      {
        path: 'tags',
        loadChildren: () => import('./pages/tags/tags.routes').then(routes => routes.routes)
      },

      {
        path: 'create-formation',
        loadComponent: () => import('./components/create-formation/create-formation.component').then(mod => mod.CreateFormationComponent)
      },
      {
        path: 'create-formation/:id',
        loadComponent: () => import('./components/create-formation/create-formation.component').then(mod => mod.CreateFormationComponent)
      },

      {
        path: 'create-article',
        loadComponent: () => import('./components/create-article/create-article.component').then(mod => mod.CreateArticleComponent)
      },
      {
        path: 'create-article/:id',
        loadComponent: () => import('./components/create-article/create-article.component').then(mod => mod.CreateArticleComponent)
      },
     
      {
        path: 'create-page',
        loadComponent: () => import('./components/create-page/create-page.component').then(mod => mod.CreatePageComponent)
      },
      {
        path: 'create-page/:id',
        loadComponent: () => import('./components/create-page/create-page.component').then(mod => mod.CreatePageComponent)
      },
    
      {
        path: 'create-tag',
        loadComponent: () => import('./components/create-tag/create-tag.component').then(mod => mod.CreateTagComponent)
      },
      {
        path: 'create-tag/:id',
        loadComponent: () => import('./components/create-tag/create-tag.component').then(mod => mod.CreateTagComponent)
      },

      {
        path: 'create-prestation',
        loadComponent: () => import('./components/create-prestation/create-prestation.component').then(mod => mod.CreatePrestationComponent)
      },
      {
        path: 'create-prestation/:id',
        loadComponent: () => import('./components/create-prestation/create-prestation.component').then(mod => mod.CreatePrestationComponent)
      },

      {
        path: 'contact',
        loadComponent: () => import('./components/contact/contact.component').then(mod => mod.ContactComponent)
      },
      {path: '**', component: Page404Component}
      
];

