import { APP_INITIALIZER, ApplicationConfig, importProvidersFrom, isDevMode } from '@angular/core';
import { provideRouter } from '@angular/router';

import { HTTP_INTERCEPTORS, provideHttpClient, withFetch, withInterceptors, withInterceptorsFromDi } from '@angular/common/http';

import { routes } from './app.routes';
import { provideClientHydration, withHttpTransferCacheOptions } from '@angular/platform-browser';

import { withInMemoryScrolling } from '@angular/router';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideToastr } from 'ngx-toastr';
import { AuthService } from './pages/auth/auth.service';
import { NgxSpinnerModule } from 'ngx-spinner';
import { HttpRequestInterceptor } from './helper/auth.interceptor';
import { authInterceptor } from './helper/auth.interceptor.fn';
import { RECAPTCHA_SETTINGS, RecaptchaSettings } from 'ng-recaptcha';
import { IMAGE_CONFIG } from '@angular/common';

export const appConfig: ApplicationConfig = {
  providers: [
    provideHttpClient(withInterceptors([authInterceptor ])),  
    
    /*
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
      deps: [AuthService]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializerFactory,
      multi: true,
      deps: [AuthService]
    },
    */
    provideRouter(routes,
      withInMemoryScrolling({
        scrollPositionRestoration: 'enabled',
        anchorScrolling: 'enabled',
      }),
      ),
    provideClientHydration(),
    // provideServiceWorker('ngsw-worker.js', {
    //   enabled: !isDevMode(),
    //   registrationStrategy: 'registerWhenStable:30000'
    // })

    provideAnimations(), // required animations providers
    provideToastr(),
    importProvidersFrom(NgxSpinnerModule.forRoot(/*config*/)),
    provideAnimations(),
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: '6LdnbDUqAAAAAC-kP-06-DEeJYegh6qIlRA_xWiK',
      } as RecaptchaSettings,
    },
    {
      provide: IMAGE_CONFIG,
      useValue: {
        disableImageSizeWarning: true, 
        disableImageLazyLoadWarning: true
      }
    }
    ]
};

export function initializerFactory(authService: AuthService) {
  return () => authService.isLoggedIn();
}


