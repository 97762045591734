<app-header></app-header>
<main>
  <ngx-spinner
    bdColor="rgba(51,51,51,0.8)"
    size="medium"
    color="#fff"
    type="ball-scale-multiple"
  >
    <p style="font-size: 20px; color: white">Chargement...</p>
  </ngx-spinner>

  <router-outlet></router-outlet>
</main>
<app-footer></app-footer>
