import { Component, OnInit } from '@angular/core';
import { CommonModule, isPlatformServer } from '@angular/common';
import { RouterLink, RouterOutlet } from '@angular/router';
import { Inject, PLATFORM_ID } from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { HeaderModule } from './components/header/header.module';
import { FooterModule } from './components/footer/footer.module';
import { NgxSpinnerModule } from 'ngx-spinner';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    RouterOutlet,
    HeaderModule,
    FooterModule,
    NgxSpinnerModule
  ],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platform: object) {

      if (isPlatformBrowser(this.platform)) {
        console.warn("browser");
        // Safe to use document, window, localStorage, etc. :-)
        console.log(document);
    }

    if (isPlatformServer(this.platform)) {
        console.warn("server");
        // Not smart to use document here, however, we can inject it ;-)
        console.log(this.document);
    }
  }

  ngOnInit(): void {

    if (isPlatformBrowser(this.platform)) {
      const navMain = this.document.getElementById('navbarCollapse');
      if (navMain) {
        navMain.onclick = function onClick() {
          if (navMain) {
            navMain.classList.remove("show");
          }
        }
      }
    }
  }

}