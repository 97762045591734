import { Component } from '@angular/core';
import { Constants } from '../../constants/Constant';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent {

  tags : Array<string>;

  constructor(){
    this.tags = Constants.tags;
  }
}